'use client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { httpBatchLink } from '@trpc/client';
import React, { useState } from 'react';
import superjson from 'superjson';
import Cookies from 'js-cookie';
//import { ENV } from "@/app/common/config";

import { trpc } from './client';
import { getBaseUrl } from '../common/env.config';

export default function Provider({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(() => new QueryClient({}));
  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        httpBatchLink({
          url: `${getBaseUrl()}/api/trpc`,
          headers() {
            const jwt = Cookies.get('jwt');
            const authorization = jwt ? `Bearer ${jwt}` : undefined;

            return {
              authorization,
              'x-request-id': 'test',
            };
          },
        }),
      ],
    }),
  );
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-right"
          toggleButtonProps={{ style: { bottom: 60 } }}
        />
      </QueryClientProvider>
    </trpc.Provider>
  );
}
